import {getOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActionsEx from './AppActions/custom';

const UsersOutlet = getOutlet('users');
const ProductsOutlet = getOutlet('products');
const CompaniesOutlet = getOutlet('companies');

class ExportHelper {
  constructor(props) {
    this.committeeIds = props.committeeIds;
    this.intExchIds = props.intExchIds;
    this.moraleIds = props.moraleIds;
    this.userIds = props.userIds;
    this.orderItemProductIds = props.orderItemProductIds;
    this.signFormProductIds = props.signFormProductIds;
    this.buyerIds = null;
    this.seniorityUserIds = props.seniorityUserIds;
    this.postExecutionIds = props.postExecutionIds;
    this.receiveRelatedIds = props.receiveRelatedIds;

    this.committeeList = null;
    this.intExchList = null;
    this.moraleList = null;
    this.userList = null;
    this.orderList = null;
    this.signFormList = null;
    this.buyerList = null;
    this.seniorityList = null;
    this.postExecutionList = null;
    this.receiveRelatedList = null;

    this.userProjection = props.userProjection || null;

    //execution
    this.execution_signform_pids = props.execution_signform_pids;
    this.execution_signform_list = null;
  }

  async fetchAll() {
    try {
      //execution
      const canFetchExecutionSignForm =
        Array.isArray(this.execution_signform_pids) &&
        this.execution_signform_pids;

      if (canFetchExecutionSignForm) {
        this.execution_signform_list = await JStorage.aggregateDocuments(
          'sign_form',
          [
            {
              $match: {
                product_id: {$in: this.execution_signform_pids},
              },
            },
            {
              $sort: {
                created: -1,
              },
            },
            {
              $addFields: {
                is_attend_members: {
                  $filter: {
                    input: '$members',
                    as: 'm',
                    cond: {
                      $eq: ['$$m.is_attend', true],
                    },
                  },
                },
                is_attend_online_members: {
                  $filter: {
                    input: '$online_members',
                    as: 'm',
                    cond: {
                      $eq: ['$$m.is_attend', true],
                    },
                  },
                },
              },
            },
            {
              $project: {
                id: {$toString: '$_id'},
                product_id: 1,
                is_posted_hours: 1,
                total_members: {
                  $cond: {
                    if: {
                      $isArray: '$is_attend_members',
                    },
                    then: {
                      $size: '$is_attend_members',
                    },
                    else: 0,
                  },
                },
                total_online_members: {
                  $cond: {
                    if: {
                      $isArray: '$is_attend_online_members',
                    },
                    then: {
                      $size: '$is_attend_online_members',
                    },
                    else: 0,
                  },
                },
              },
            },
          ],
        );
      }

      if (Array.isArray(this.committeeIds) && this.committeeIds.length > 0) {
        this.committeeList = await JStorage.fetchAllDocuments(
          'Committee',
          {
            _id: {$in: this.committeeIds.map((id) => ({$oid: id}))},
          },
          {NAME: 1},
        );
      }

      if (Array.isArray(this.intExchIds) && this.intExchIds.length > 0) {
        this.intExchList = await JStorage.fetchAllDocuments(
          'international_exchange',
          {_id: {$in: this.intExchIds.map((id) => ({$oid: id}))}},
          {title: 1},
        );
      }

      if (Array.isArray(this.moraleIds) && this.moraleIds.length > 0) {
        this.moraleList = await JStorage.fetchAllDocuments(
          'morale',
          {
            _id: {$in: this.moraleIds.map((id) => ({$oid: id}))},
          },
          {case_number: 1},
        );
      }

      if (Array.isArray(this.userIds) && this.userIds.length > 0) {
        this.userList = await JStorage.fetchAllDocuments(
          'user_profile',
          {
            owner: {$in: this.userIds.map((owner) => owner)},
          },
          null,
          this.userProjection,
        );
      }

      if (
        Array.isArray(this.orderItemProductIds) &&
        this.orderItemProductIds.length > 0
      ) {
        this.orderList = await JStorage.fetchAllDocuments(
          'order',
          {
            'items.0.product.id': {
              $in: this.orderItemProductIds.map((id) => ({$oid: id})),
            },
          },
          null,
          {
            order_id: 1,
            owner: 1,
            owner_state: 1,
            owner_substate: 1,
            buyer_name: 1,
            buyer_phone: 1,
            total: 1,
            status: 1,
            payment_status: 1,
            payment_subtype: 1,
            receipt_email_date: 1,
            insurance: 1,
            vegetarians: 1,
            items: 1,
          },
        );

        this.buyerIds = this.orderList.map((o) => o.owner);

        if (Array.isArray(this.buyerIds) && this.buyerIds.length > 0) {
          this.buyerList = await JStorage.fetchAllDocuments(
            'user_profile',
            {
              owner: {$in: this.buyerIds.map((owner) => owner)},
            },
            null,
            {
              owner: 1,
              SEX: 1,
              INFORM_TO: 1,
              email: 1,
              EMAIL2: 1,
              mail_address_city: 1,
              mail_address_zip_code: 1,
              mail_address_detail: 1,
              company_current: 1,
              company_zip_code: 1,
              company_city: 1,
              company_detail: 1,
              company_phone: 1,
              company_fax: 1,
              id_card_number: 1,
              birthday: 1,
              state: 1,
              substate: 1,
            },
          );
        }
      }

      if (
        Array.isArray(this.signFormProductIds) &&
        this.signFormProductIds.length > 0
      ) {
        this.signFormList = await JStorage.fetchAllDocuments(
          'sign_form',
          {
            product_id: {$in: this.signFormProductIds},
          },
          ['-created'],
          null,
        );
      }

      if (
        Array.isArray(this.seniorityUserIds) &&
        this.seniorityUserIds.length > 0
      ) {
        this.seniorityList = await AppActionsEx.getUserSeniorities({
          users_id: this.seniorityUserIds,
        });
      }

      if (
        Array.isArray(this.postExecutionIds) &&
        this.postExecutionIds.length > 0
      ) {
        this.postExecutionList = await JStorage.fetchAllDocuments(
          'form',
          {
            type: 'post',
            _id: {$in: this.postExecutionIds.map((id) => ({$oid: id}))},
          },
          ['-created'],
          {text_number: 1},
        );
      }

      if (
        Array.isArray(this.receiveRelatedIds) &&
        this.receiveRelatedIds.length > 0
      ) {
        let limit = 100;

        if (this.receiveRelatedIds.length > limit) {
          const promiseArray = [];
          const counts = Math.ceil(this.receiveRelatedIds.length / limit);

          let results = [];

          for (let c = 1; c < counts + 1; c++) {
            const ids = this.receiveRelatedIds
              .map((id) => {
                return {$oid: id};
              })
              .filter((_, index) => {
                if (c === 1) {
                  return index > 0 && index < c * limit;
                } else {
                  return index > (c - 1) * limit && index < c * limit;
                }
              });

            promiseArray.push(
              JStorage.fetchDocuments(
                'form',
                {
                  type: 'receive',
                  _id: {
                    $in: ids,
                  },
                },
                ['-created'],
                null,
                {paperwork_id: 1},
              ),
            );
          }

          const respArray = await Promise.all(promiseArray);

          for (let n = 0; n < respArray.length; n++) {
            results = [...results, ...respArray[n]];
          }

          this.receiveRelatedList = results;
        } else {
          this.receiveRelatedList = await JStorage.fetchAllDocuments(
            'form',
            {
              type: 'receive',
              _id: {
                $in: this.receiveRelatedIds.map((id) => ({$oid: id})),
              },
            },
            ['-created'],
            {paperwork_id: 1},
          );
        }
      }
    } catch (err) {
      console.warn(err);
    }
  }

  //execution
  getExecutionSignformByProduct(id) {
    const index = this.execution_signform_list.findIndex(
      (it) => it.product_id === id,
    );

    const isPostedIndex = this.execution_signform_list.findIndex(
      (it) => it.product_id === id && it.is_posted_hours,
    );

    if (isPostedIndex > -1) {
      return this.execution_signform_list[isPostedIndex];
    }

    return this.execution_signform_list[index];
  }

  getCommittee(id) {
    const index = this.committeeList.findIndex((item) => item.id === id);
    return index > -1 ? this.committeeList[index] : {};
  }

  getIntExch(id) {
    const index = (this.intExchList || []).findIndex((item) => item.id === id);
    return index > -1 ? this.intExchList[index] : {};
  }

  getMorale(id) {
    const index = this.moraleList.findIndex((item) => item.id === id);
    return index > -1 ? this.moraleList[index] : {};
  }

  getProduct(id) {
    return ProductsOutlet.getValue().find((item) => item.id === id) || {};
  }

  getUser(owner) {
    return UsersOutlet.getValue().find((item) => item.owner === owner) || {};
  }

  getUserFullData(owner) {
    const index = this.userList.findIndex((item) => item.owner === owner);
    return index > -1 ? this.userList[index] : {};
  }

  getUserPartialData(owner) {
    const index = this.userList.findIndex((item) => item.owner === owner);
    return index > -1 ? this.userList[index] : {};
  }

  getCompany(id) {
    return CompaniesOutlet.getValue().find((item) => item.id === id) || {};
  }

  getOrdersByProductId(id) {
    return this.orderList
      ? this.orderList.filter((order) => order.items[0].product.id.$oid === id)
      : [];
  }

  getOrder(id) {
    const index = this.orderList.findIndex((item) => item.id === id);
    return index > -1 ? this.orderList[index] : {};
  }

  getBuyerInfo(owner) {
    const index = this.buyerList.findIndex((item) => item.owner === owner);
    return index > -1 ? this.buyerList[index] : {};
  }

  getLatestSignForm(product_id) {
    const index = this.signFormList.findIndex(
      (item) => item.product_id === product_id,
    );

    const isPostedIndex = this.signFormList.findIndex(
      (item) => item.product_id === product_id && item.is_posted_hours,
    );

    return isPostedIndex === -1
      ? this.signFormList[index] || {}
      : this.signFormList[isPostedIndex] || {};
  }

  getUserSeniority(owner) {
    const index = this.seniorityList.findIndex((item) => item.owner === owner);
    return index > -1 ? this.seniorityList[index] : {};
  }

  getPostExecution(id) {
    const index = this.postExecutionList.findIndex((item) => item.id === id);
    return index > -1 ? this.postExecutionList[index] : {};
  }

  getReceiveRelated(id) {
    const index = this.receiveRelatedList.findIndex((item) => item.id === id);
    return index > -1 ? this.receiveRelatedList[index] : {};
  }
}

export default ExportHelper;
